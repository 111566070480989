<template>
<div class="about" v-html="$t('about.msg')">
</div>
</template>
<script>
import i18n from './i18n'
export default {
  name: 'about',
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
    }
  }
}
</script>
